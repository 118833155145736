<template>
  <div class="home">
    <section class="home--section-left">
      <v-img :src="require('@/assets/images/bolle-bg.jpg')" position="left center"></v-img>
    </section>
    <section class="home--section-right pa-4 pa-sm-6 pa-lg-8">
      <div class="home__container">
        <header
          class="home__container__header"
          :class="{ 'home__container__header--two-columns': !logoByLocale('src') }"
        >
          <div class="home__container__header__left">
            <a href="https://www.bolle.com/" target="_blank">
              <img :src="require('@/assets/images/logo-bolle.svg')" alt="Bollé" />
            </a>
          </div>
          <div
            class="home__container__header__center"
            :class="{ 'home__container__header__center--two-columns': !logoByLocale('src') }"
          >
            <a href="https://www.hauteroute.org/">
              <img :src="require('@/assets/images/logo-haute-route.svg')" alt="Haute Route" />
            </a>
          </div>
          <div class="home__container__header__right" v-if="logoByLocale('src')">
            <a :href="logoByLocale('link')" target="_blank">
              <img :src="require(`@/assets/images/${logoByLocale('src')}`)" :alt="logoByLocale('name')" />
            </a>
          </div>
        </header>
        <div class="home__container__main">
          <div class="home__container__main__intro text-center mt-12">
            {{ $t('home.intro') }}
          </div>
          <h1 class="home__container__main__title text-center mt-4">{{ $t('home.title') }}</h1>
          <div class="home__container__main__separator">
            <span class="home__container__main__separator__label px-4">{{ $t('home.separator') }}</span>
          </div>
          <h2 class="home__container__main__subtitle text-center">{{ $t('home.subtitle') }}</h2>
          <div class="home__container__main__caption text-center">{{ $t('home.caption') }}</div>
          <div class="home__container__main__prices text-center mt-6" v-html="$t('home.prices')"></div>
          <v-btn
            depressed
            tile
            color="secondary"
            x-large
            height="54"
            :href="linkHref"
            class="home__container__main__link text-h6 mt-8"
          >
            {{ $t('home.cta') }}
          </v-btn>
        </div>
        <div class="home__container__language mt-6">
          <div class="home__container__language__label mb-2">{{ $t('home.language.label') }}</div>

          <v-select
            class="home__container__language__label__selector"
            :items="items"
            v-model="currentLanguage"
            outlined
            hide-details
            solo
            @change="onChange"
          >
            <template v-slot:selection="{ item }">
              <span class="d-flex justify-center text-uppercase" style="width: 100%">
                {{ item.text }}
              </span>
            </template></v-select
          >
        </div>
        <div class="home__container__infos text-center mt-8" v-html="$t('home.infos')"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: vm => ({
    currentLanguage: vm.$i18n.locale,
    items: [
      {
        text: 'English',
        value: 'en',
        href: 'https://bolle-en.gtr.cx/?g=bolleEn01',
        logo: {
          name: 'Rx Sport',
          src: 'logo-rxsport.svg',
          link: 'https://www.rxsport.co.uk/',
        },
      },
      // {
      //   text: 'Français',
      //   value: 'fr',
      //   href: 'https://bolle-fr.gtr.cx/?g=bolleFr01',
      //   logo: {
      //     name: 'Alltricks',
      //     src: 'logo-alltricks.svg',
      //     link: 'https://www.alltricks.fr/',
      //   },
      // },
      {
        text: 'Italiano',
        value: 'it',
        href: 'https://bolle-it.gtr.cx/?g=bolleIt01',
        logo: {
          name: 'All4cycling',
          src: 'logo-all4cycling.svg',
          link: 'https://www.all4cycling.com/',
        },
      },
      {
        text: 'Español',
        value: 'es',
        href: 'https://bolle-es.gtr.cx/?g=bolleEs01',
        logo: {
          name: 'Top Fun',
          src: 'logo-topfun.svg',
          link: 'https://www.topfun.com/es/',
        },
      },
      // {
      //   text: 'Deutsch',
      //   value: 'de',
      //   href: 'https://bolle-it.gtr.cx/?g=bolleDe01',
      // },
    ],
  }),
  metaInfo: vm => ({
    title: vm.$t('meta.title'),
    meta: [{ name: 'description', content: vm.$t('meta.description') }],
  }),
  computed: {
    itemByLocale() {
      return this.items.find(item => item.value === this.currentLanguage)
    },
    linkHref() {
      const item = this.itemByLocale
      return item.href
    },
  },
  methods: {
    onChange(locale) {
      this.$i18n.locale = locale
    },
    logoByLocale(param) {
      const item = this.itemByLocale
      return item.logo ? item.logo[param] : null
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  min-height: 100%;

  &--section-left {
    display: none;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      width: calc(100% - 600px);
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: calc(100% - 800px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: calc(100% - 1200px);
    }
  }

  &--section-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 600px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: 800px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: 1200px;
    }
  }

  &__logo {
    position: absolute;
    bottom: $gutter-tablet;
    left: $gutter-tablet;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      bottom: $gutter-desktop;
      left: $gutter-desktop;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      justify-content: space-between;
      max-width: 550px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;

      &--two-columns {
        grid-template-columns: repeat(2, 1fr);
      }

      &__left,
      &__center,
      &__right {
        display: flex;
        position: relative;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }

      &__center {
        &:before,
        &:after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.8);
          width: 2px;
          height: 70%;
          content: '';
        }

        &:before {
          left: -9px;

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            left: -6px;
          }
        }

        &:after {
          right: -9px;

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            right: -9px;
          }
        }

        &--two-columns {
          &:after {
            content: none;
          }
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      &__intro {
        text-transform: initial;
        font-size: 1.375rem;
      }

      &__title {
        color: $color-text-main;
        font-size: 1.875rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.5rem;
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        text-align: center;
        color: $color-text-main;
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.7);
          height: 2px;
          content: '';
        }

        &__label {
          position: relative;
          background-color: $color-bg;
        }
      }

      &__subtitle {
        text-transform: uppercase;
        color: $color-text-accent;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.25rem;
        }
      }

      &__caption {
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }
      }

      &__prices {
        text-transform: initial;
        color: $color-text-alt;
      }

      &__link {
        font-weight: bold;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        text-transform: uppercase;
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.5rem;
        }
      }
    }

    &__infos {
      text-transform: uppercase;
      font-size: 1.125rem;
    }
  }
}
</style>
